































































































































































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import EditCustomDefineByViewModel from
  '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-custom-goal-plan/edit-custom-define-by-view-model';

@Component({
  name: 'EditCustomDefineBy',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class EditCustomDefineBy extends Vue {
  @Prop({ type: String, required: true })
  customGoalName!: string;

  edit_custom_define_by_view_model = Vue.observable(
    new EditCustomDefineByViewModel(this),
  );

  async mounted() {
    await this.edit_custom_define_by_view_model.initialize();
  }
}
