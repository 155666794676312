import Vue from 'vue';
import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import { minValueRule, requiredRule } from '@/vue-app/utils/form-rules';

// Application
import GetCustomInvestorGoalCalculatorQuery
  from '@/modules/flagship/custom-investor-goal-calculator/application/queries/get-custom-investor-goal-calculator-query';
import CalculateCustomGoalMinimumAccumulatedAmountQuery
  from '@/modules/flagship/custom-investor-goal-calculator/application/queries/calculate-custom-goal-minimum-accumulated-amount-query';
import EditCustomGoalPlanCurrentPlanService
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/application/services/edit-custom-goal-plan-current-plan-service';
import EditCustomGoalPlanInitialAmountService
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/application/services/edit-custom-goal-plan-initial-amount-service';
import EditCustomGoalPlanDefineByService
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/application/services/edit-custom-goal-plan-define-by-service';

// Domain
import { CustomInvestorGoalCalculatorDto }
  from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-investor-goal-calculator-dto';
import { DefineByEntity }
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/domain/entities/define-by-entity';
import { CustomGoalMinimumAccumulatedAmountCalculationDto }
  from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-goal-minimum-accumulated-amount-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class EditCustomDefineByViewModel {
  @Inject(TYPES.GET_CUSTOM_INVESTOR_GOAL_CALCULATOR_QUERY)
  private readonly get_custom_calculator_query!: GetCustomInvestorGoalCalculatorQuery;

  @Inject(TYPES.CALCULATE_CUSTOM_GOAL_MINIMUM_ACCUMULATED_AMOUNT_QUERY)
  // eslint-disable-next-line max-len
  private readonly calculate_maximum_initial_amount!: CalculateCustomGoalMinimumAccumulatedAmountQuery;

  @Inject(TYPES.EDIT_CUSTOM_GOAL_CURRENT_PLAN_SERVICE)
  private readonly current_plan_service!: EditCustomGoalPlanCurrentPlanService;

  @Inject(TYPES.EDIT_CUSTOM_GOAL_PLAN_INITIAL_AMOUNT_SERVICE)
  private readonly initial_amount_service!: EditCustomGoalPlanInitialAmountService;

  @Inject(TYPES.EDIT_CUSTOM_GOAL_PLAN_DEFINE_BY_SERVICE)
  private readonly define_by_service!: EditCustomGoalPlanDefineByService;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.edit-plan-goals.edit-custom-goal-plan.define_by';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  readonly min_monthly_contribution = 500;

  defined_by = 'by_time';

  time_to_reach_goal = 6;

  min_months_to_reach_goal = 1;

  months_to_reach_goal_by_contribution = 0;

  contribution = '';

  new_target_amount = '';

  current_amount = 0;

  slider_contribution = 1;

  timer?: NodeJS.Timer;

  monthly_required_amount = 0;

  accumulated_amount_by_time = 0;

  accumulated_amount_by_contribution = 0;

  minimum_accumulated_amount = 10000000;

  interest_rate = 0;

  is_define_by_contribution = false;

  is_valid_form = false;

  wants_years = false;

  is_loading = false;

  associated_product_id = '';

  performance_percentage = '';

  input_rules = {
    new_target_amount: [
      requiredRule,
      (value: string) => minValueRule(
        value.replace(/[^0-9.-]/g, ''), `${currencyFormat(this.minimum_accumulated_amount)}`, this.minimum_accumulated_amount,
      ),
    ],
    contribution: [requiredRule, (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), `$${this.min_monthly_contribution} MXN`, this.min_monthly_contribution)],
  };

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    await this.setInitialValues();
  }

  get you_must_save() {
    return (this.is_define_by_contribution) ? this.months_to_reach_goal_by_contribution
      .toFixed(1) : this.getAmountFormatted(this.monthly_required_amount);
  }

  get is_continue_btn_disabled() {
    return !this.is_valid_form || this.is_loading;
  }

  get user_wants_years_label() {
    return this.wants_years ? 'by_time.number_of_years' : 'by_time.number_of_months';
  }

  get btn_years_text() {
    return this.translate(this.wants_years ? 'less_than_a_year' : 'more_than_a_year');
  }

  get max_value_slider() {
    return this.wants_years ? 40 : 11;
  }

  get fixed_time_adjusted() {
    return this.wants_years
      ? this.time_to_reach_goal * 12
      : this.time_to_reach_goal;
  }

  get max_contribution() {
    return (this.parseCurrencyToNumber(this.new_target_amount)) ? this
      .parseCurrencyToNumber(this.new_target_amount) - this.current_amount : 0;
  }

  getCustomClassOpacity = (is_option_by_time: boolean) => {
    this.defined_by = (!this.is_define_by_contribution) ? 'by_time' : 'by_contribution';
    if ((this.is_define_by_contribution && is_option_by_time) || (!this
      .is_define_by_contribution && !is_option_by_time)) return 'opacity';
    return '';
  }

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  getCurrentPlanInformation = () => (this.current_plan_service.getCurrentPlanInformation());

  getInitialAmountInformation = () => (this.initial_amount_service.getInitialAmountInformation());

  getDefineByInformation = () => (this.define_by_service.getDefineByInformation());

  setDefineByInformation = (define_by: DefineByEntity) => {
    this.define_by_service.setDefineByInformation(define_by);
  };

  setInitialValues = async () => {
    const current_plan = this.getCurrentPlanInformation();
    const initial_amount = this.getInitialAmountInformation();
    const define_by = this.getDefineByInformation();
    this.current_amount = this.parseCurrencyToNumber(current_plan.current_amount);
    this.associated_product_id = current_plan.associated_product_id;
    this.performance_percentage = (current_plan.associated_product_name === 'sowos_wealth') ? '7' : '5.5';
    if (initial_amount.there_is_more_savings === 'yes') {
      this.current_amount += initial_amount.additional_amount;
    }
    if (define_by.by_time.months_to_reach_goal > 0) {
      this.is_define_by_contribution = define_by.is_defined_by_contribution;
      this.new_target_amount = String(define_by.new_target_amount);
      this.is_define_by_contribution = define_by.is_defined_by_contribution;
      this.time_to_reach_goal = define_by.by_time.months_to_reach_goal;
      this.contribution = String(define_by.by_contribution.contribution);
      this.slider_contribution = define_by.by_contribution.contribution;
      this.accumulated_amount_by_contribution = define_by.by_contribution.accumulated_amount;
      this.months_to_reach_goal_by_contribution = define_by.by_contribution.months_to_reach_goal;
      this.wants_years = define_by.by_time.wants_years;
      this.monthly_required_amount = define_by.by_time.you_must_save;
      this.accumulated_amount_by_time = define_by.by_time.accumulated_amount;
      await this.calculateMonthlyPayment();
    } else {
      this.delay();
      await this.calculateMinimumAccumulatedAmount();
    }
  }

  validateContributionIsLessThanMax = () => {
    const parsed_contribution = this.parseCurrencyToNumber(this.contribution);
    if (parsed_contribution > this.max_contribution) {
      this.contribution = this.max_contribution.toFixed(0);
    }
    this.slider_contribution = this.parseCurrencyToNumber(this.contribution);
  }

  parseCurrencyToNumber = (currency: string) => parseFloat(currency.replace(/[^0-9.]/g, ''));

  definedByChange = async () => {
    await this.calculateMinimumAccumulatedAmount();
  }

  calculateMinimumAccumulatedAmount = async () => {
    try {
      this.is_loading = true;
      if (!this.is_define_by_contribution || (
        this.is_define_by_contribution && this.parseCurrencyToNumber(this.contribution) === 0)
      ) {
        const interest_rate = parseFloat(this.performance_percentage) / 100;
        const payload: CustomGoalMinimumAccumulatedAmountCalculationDto = {
          initial_amount: this.current_amount,
          monthly_required_amount: this.contribution
            ? this.parseCurrencyToNumber(this.contribution) : 0,
          fixed_time_adjusted: this.is_define_by_contribution ? 1 : this.fixed_time_adjusted,
          interest_rate,
        };
        const {
          minimum_accumulated_amount,
        } = await this.calculate_maximum_initial_amount.execute(payload);
        this.minimum_accumulated_amount = Math.ceil(parseFloat(minimum_accumulated_amount));
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.view.$refs.form_edit_custom_goal_plan.validate();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_minimum_accumulated_amount'));
    } finally {
      this.is_loading = false;
    }
  }

  calculateMonthlyPayment = async () => {
    try {
      this.is_loading = true;
      const new_target_amount = this.parseCurrencyToNumber(this.new_target_amount);
      await this.calculateMinimumAccumulatedAmount();
      if (new_target_amount > 0) {
        if (this.is_valid_form) {
          const payload: CustomInvestorGoalCalculatorDto = {
            associated_product_id: this.associated_product_id,
            defined_by: (this.is_define_by_contribution) ? 'contribution' : 'time',
            desired_amount: new_target_amount,
            initial_amount: this.current_amount,
            monthly_required_amount: (this.is_define_by_contribution) ? this
              .parseCurrencyToNumber(this.contribution) : 0,
            fixed_time_adjusted: (this.is_define_by_contribution) ? 0 : this.fixed_time_adjusted,
          };
          const {
            custom_investor_goal_calculation,
          } = await this.get_custom_calculator_query.execute(payload);
          const {
            accumulated_amount, monthly_required_amount, fixed_time_adjusted, interest_rate,
          } = custom_investor_goal_calculation;
          this.interest_rate = interest_rate;
          if (this.is_define_by_contribution) {
            this.months_to_reach_goal_by_contribution = fixed_time_adjusted;
            this.accumulated_amount_by_contribution = accumulated_amount;
          } else {
            this.monthly_required_amount = monthly_required_amount;
            this.accumulated_amount_by_time = accumulated_amount;
          }
        }
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_monthly_payment'));
    } finally {
      this.is_loading = false;
    }
  }

  toggleWantsYears = async () => {
    this.wants_years = !this.wants_years;
    await this.calculateMonthlyPayment();
  }

  delay = () => {
    this.is_loading = true;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      if (this.is_valid_form) {
        if (this.is_define_by_contribution) this.validateContributionIsLessThanMax();
        await this.calculateMonthlyPayment();
      }
    }, 2000);
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  setNewContribution = () => {
    this.contribution = String(this.slider_contribution);
    this.delay();
  }

  nextStep = () => {
    const define_information: DefineByEntity = {
      new_target_amount: this.parseCurrencyToNumber(this.new_target_amount),
      is_defined_by_contribution: this.is_define_by_contribution,
      by_time: {
        wants_years: this.wants_years,
        months_to_reach_goal: this.time_to_reach_goal,
        you_must_save: this.monthly_required_amount,
        accumulated_amount: this.accumulated_amount_by_time,
      },
      by_contribution: {
        contribution: this.parseCurrencyToNumber(this.contribution),
        months_to_reach_goal: this.months_to_reach_goal_by_contribution,
        accumulated_amount: this.accumulated_amount_by_contribution,
      },
    };
    this.setDefineByInformation(define_information);
    this.view.$emit('nextStep');
  }
}
